import type { SystemStyleObject } from '@chakra-ui/react';
import { Skeleton, Flex, useColorModeValue, chakra, Text } from '@chakra-ui/react';
import React from 'react';

import app from 'configs/app/app';
import breakpoints from 'theme/foundations/breakpoints';
import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';

const cartBackground = app.baseUrl + '/static/backgrounds/cart.png';

type Props = {
  icon: IconName;
  title: string;
  value: string | React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
  url?: string;
  isLoading?: boolean;
}

const LARGEST_BREAKPOINT = '1240px';

const StatsItem = ({ icon, title, value, className, tooltip, url, isLoading }: Props) => {
  const sxContainer: SystemStyleObject = {
    [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { flexDirection: 'column' },
  };

  const sxText: SystemStyleObject = {
    [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { alignItems: 'center' },
  };

  const bgColor = useColorModeValue('blue.50', 'whiteAlpha.100');
  const loadingBgColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.50');

  return (
    <Flex
      backgroundColor={ isLoading ? loadingBgColor : bgColor }
      backgroundImage={ cartBackground }
      backgroundSize="100% 100%"
      padding={ 3 }
      borderRadius="md"
      flexDirection="row"
      sx={ sxContainer }
      border="2px solid rgba(255, 255, 255, 0.22)"
      backdropBlur="31px"
      alignItems="center"
      columnGap={ 3 }
      rowGap={ 2 }
      className={ className }
      color={ useColorModeValue('black', 'white') }
      position="relative"
      { ...(url && !isLoading ? {
        as: 'a',
        href: url,
      } : {}) }
    >
      <IconSvg name={ icon } boxSize={ 7 } isLoading={ isLoading } borderRadius="base"/>
      <Flex
        flexDirection="column"
        alignItems="start"
        sx={ sxText }
      >
        <Skeleton
          isLoaded={ !isLoading }
          color={ useColorModeValue('black', 'white') }
          fontSize={{ base: '10px', md: '10px' }}
          fontWeight={ 400 }
          letterSpacing="0.1px"
          lineHeight="20px"
          textTransform="uppercase"
        >
          <span>{ title }</span>
        </Skeleton>
        <Skeleton isLoaded={ !isLoading } fontWeight={ 500 } fontSize="md" color={ useColorModeValue('black', 'white') } borderRadius="base">
          { typeof value === 'string' ? <Text bgClip="text" bgGradient="linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)">{ value }</Text> : value }
        </Skeleton>
      </Flex>
      { tooltip }
    </Flex>
  );
};

export default chakra(StatsItem);
